import styled from 'styled-components'
import device from '../../utils/device-size'

export default styled.div`
  position: relative;
  border: none;
  .icon-wrapper {
    width: 40px;
    height: 40px;
    &:hover {
      cursor: pointer;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .filter-overlay {
    display: none;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    background-color: transparent;
    &.open {
      display: block;
    }
  }
  .popup-wrapper {
    display: none;
    z-index: 6;
    position: absolute;
    width: 296px;
    height: 703px;
    padding: 24px;
    right: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #1e1e1e;
    &.open {
      display: block;
    }
    .header-wrapper {
      font-size: 18px;
      justify-content: space-between;
      margin-bottom: 24px;
      height: 21px;
      .reset-filter-button {
        width: 80px;
        height: 21px;
        font-size: 12px;
        padding: 0;
      }
    }
    .filter-container {
      height: calc(100% - 141px); /* 141px is from header and footer */
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 8px;
        background-color: #1e1e1e;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #404040;
        width: 8px;
        height: 100px;
      }
      .grey-line {
        margin-top: 24px;
        margin-bottom: 16px;
        width: 230px;
        height: 1px;
        background-color: #4a4a4a;
      }
      .camera-filter-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .dropdown-wrapper {
          .value-tag {
            margin-top: 1px;
            margin-bottom: 1px;
          }
        }
      }
      .vehicle-filter-wrapper {
        .dropdown-wrapper {
          .value-tag {
            margin-top: 1px;
            margin-bottom: 1px;
          }
        }
      }
      .time-period-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .time-period-label {
          font-size: 12px;
          color: #fff;
        }
        .time-option-wrapper {
          margin-top: 16px;
          width: 189px;
          .drop-down-value-container {
            border: 1px solid #981214;
            width: 189px;
            height: 24px;
            padding: 2px 8px;
            .drop-down-value {
              font-size: 12px;
            }
          }
          .drop-down-menu-container {
            width: 189px;
            .drop-down-menu {
              height: 24px;
              border: 1px solid #981214;
              font-size: 12px;
              padding: 2px 8px;
            }
          }
        }
        .date-filter-wrapper {
          margin-top: 16px;
          width: 189px;
          .date-wrapper {
            border: 1px solid #981214;
            width: 189px;
            height: 24px;
            padding: 3px 8px;
            .date-picker-component {
              width: calc(100% - 16px);
              .MuiTextField-root {
                width: 100%;
              }
              .input-wrapper {
                input {
                  font-size: 12px !important;
                  padding: 8px 0;
                  width: 100% !important;
                }
                &:before,
                :after {
                  border-width: initial !important;
                  border-style: none !important;
                  border-color: initial !important;
                  border-image: initial !important;
                }
              }
            }
            .icon-wrapper {
              display: flex;
              width: 16px;
              height: 16px;
              margin-left: auto;
            }
          }
        }
      }
      .time-from-to-wrapper {
        .allday-checkbox {
          width: 189px;
          margin: 0 auto;
        }
        .dropdown-from-to-wrapper {
          align-items: center;
          width: 189px;
          margin: 0 auto;
          justify-content: space-between;
          .fromtime-dropdown {
            width: 80px;
            .drop-down-value-container {
              border: 1px solid #981214;
              height: 24px;
              .drop-down-value {
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .drop-down-menu-container {
              transform: none;
              .drop-down-menu {
                font-size: 12px;
              }
            }
          }
          .center-line {
            width: 19px;
            height: 1px;
            background-color: #979797;
            margin-top: 35px;
            margin-left: -16px;
            margin-right: -16px;
          }
          .totime-dropdown {
            width: 80px;
            .drop-down-value-container {
              border: 1px solid #981214;
              height: 24px;
              .drop-down-value {
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .drop-down-menu-container {
              transform: none;
              .drop-down-menu {
                font-size: 12px;
              }
            }
          }
        }
      }
      .sortby-container {
        label {
          margin-bottom: 16px;
          display: block;
        }
      }
    }
    .filter-footer-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 32px;
      margin-bottom: 16px;
      .grey-line {
        margin-bottom: 32px;
        width: 230px;
        height: 1px;
        background-color: #4a4a4a;
      }
      .apply-filter-button {
        width: 132px;
        height: 24px;
        font-size: 12px;
        padding: 0;
      }
    }
  }
  @media only screen and (min-width: ${device.minSizeM}) and (max-width: ${device.maxSizeM}) {
    .popup-wrapper {
      height: 600px;
    }
  }
`
