import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import FilterStyled from './styled'

import Label from '../Label/dynamic'
import Button from '../Button/dynamic'
import CheckBox from '../CheckBox/dynamic'
import MultiSelectDropdown from '../MultiSelectDropdown/dynamic'
import DatePicker from '../DatePicker/dynamic'
import Dropdown from '../Dropdown/dynamic'
import ButtonGroup from '../ButtonGroup/dynamic'

import FilterIconSrc from '../../assets/images/visitor/filter.svg'
import FilterActiveIconSrc from '../../assets/images/visitor/filter-active.svg'

import { withTranslation } from '../../../i18n'

const FROM_TIME_OPTIONS = [
  { text: '00:00', value: 0 },
  { text: '01:00', value: 1 },
  { text: '02:00', value: 2 },
  { text: '03:00', value: 3 },
  { text: '04:00', value: 4 },
  { text: '05:00', value: 5 },
  { text: '06:00', value: 6 },
  { text: '07:00', value: 7 },
  { text: '08:00', value: 8 },
  { text: '09:00', value: 9 },
  { text: '10:00', value: 10 },
  { text: '11:00', value: 11 },
  { text: '12:00', value: 12 },
  { text: '13:00', value: 13 },
  { text: '14:00', value: 14 },
  { text: '15:00', value: 15 },
  { text: '16:00', value: 16 },
  { text: '17:00', value: 17 },
  { text: '18:00', value: 18 },
  { text: '19:00', value: 19 },
  { text: '20:00', value: 20 },
  { text: '21:00', value: 21 },
  { text: '22:00', value: 22 },
  { text: '23:00', value: 23 },
]

const TO_TIME_OPTIONS = [
  { text: '00:59', value: 1 },
  { text: '01:59', value: 2 },
  { text: '02:59', value: 3 },
  { text: '03:59', value: 4 },
  { text: '04:59', value: 5 },
  { text: '05:59', value: 6 },
  { text: '06:59', value: 7 },
  { text: '07:59', value: 8 },
  { text: '08:59', value: 9 },
  { text: '09:59', value: 10 },
  { text: '10:59', value: 11 },
  { text: '11:59', value: 12 },
  { text: '12:59', value: 13 },
  { text: '13:59', value: 14 },
  { text: '14:59', value: 15 },
  { text: '15:59', value: 16 },
  { text: '16:59', value: 17 },
  { text: '17:59', value: 18 },
  { text: '18:59', value: 19 },
  { text: '19:59', value: 20 },
  { text: '20:59', value: 21 },
  { text: '21:59', value: 22 },
  { text: '22:59', value: 23 },
  { text: '23:59', value: 24 },
]

export class FilterComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPopupOpen: false,
      visitorFilter: props.visitorFilter,
    }
  }

  togglePopup = () => {
    this.setState(state => ({ isPopupOpen: !state.isPopupOpen }))
  }

  closePopup = () => {
    this.setState({
      isPopupOpen: false,
    })
  }

  renderIcon() {
    const icon = this.state.isPopupOpen ? FilterActiveIconSrc : FilterIconSrc
    return (
      <div className="icon-wrapper filter-icon" onClick={this.togglePopup}>
        <img src={icon} />
      </div>
    )
  }

  onResetButtonClick = () => {
    this.props.onFilterReset()
  }

  renderFilterHeader() {
    return (
      <div className="header-wrapper flex align-items-center">
        <div className="header-wrapper-text">{this.props.t('filter')}</div>
        <Button className="reset-filter-button" text={this.props.t('reset')} onClick={this.onResetButtonClick} invert />
      </div>
    )
  }

  renderCameraFilter() {
    return (
      <div className="camera-filter-wrapper">
        <MultiSelectDropdown
          id="camera-filter"
          label={this.props.t('camera')}
          allTagText={this.props.t('all_camera')}
          placeholder={this.props.t('please_select_camera')}
          options={this.props.listCamera}
          value={this.props.visitorFilter.camera}
          onChange={value => this.props.onMultiDropdownChange('camera', value)}
          searchable
        />
        <div className="grey-line" />
      </div>
    )
  }

  onTimeFilterDropdownChange = value => {
    this.props.onMultiDropdownChange('timePeriodType', value)
  }

  onDateFilterChange = date => {
    this.props.onMultiDropdownChange('dateFilter', date)
  }

  renderTimePeriod() {
    const timeFilterOptions = [
      { text: this.props.t('day'), value: 'day' },
      { text: this.props.t('week'), value: 'week' },
      { text: this.props.t('month'), value: 'month' },
      { text: this.props.t('year'), value: 'year' },
    ]
    return (
      <div className="time-period-wrapper">
        <Label className="time-period-label" text={this.props.t('time_period')} />
        <div className="time-option-wrapper">
          <Dropdown
            className="time-filter-dropdown"
            dropdownLabel={this.props.t('time_period')}
            options={timeFilterOptions}
            value={this.props.visitorFilter.timePeriodType}
            onChange={this.onTimeFilterDropdownChange}
            placeholder={this.props.t('select_time_period')}
          />
        </div>
        <div className="date-filter-wrapper">
          <DatePicker
            className={'date-picker-filter'}
            type={this.props.visitorFilter.timePeriodType}
            date={this.props.visitorFilter.dateFilter}
            changeDate={this.onDateFilterChange}
          />
        </div>
        <div className="grey-line" />
      </div>
    )
  }

  onTimeChange(property, time) {
    this.props.onTimeChange(property, time)
  }

  handleAllDayCheckboxChanged = checked => {
    this.props.onAllDayCheckBoxChange(checked)
  }

  shouldDisabledAllDayCheckbox = () => {
    let disabled = false
    const isSelectedDateIsToday = moment().isSame(this.props.visitorFilter.dateFilter, 'd')
    const isDayPeriod = this.props.visitorFilter.timePeriodType === 'day'
    if (isSelectedDateIsToday && isDayPeriod) {
      disabled = true
    }
    return disabled
  }

  getStartTimeOptions = () => {
    let allOptions = FROM_TIME_OPTIONS
    const isSelectedDateIsToday = moment().isSame(moment(this.props.visitorFilter.dateFilter), 'day')
    if (isSelectedDateIsToday && this.props.visitorFilter.timePeriodType === 'day') {
      const currentHours = moment(this.props.visitorFilter.dateFilter).hours()
      allOptions = FROM_TIME_OPTIONS.filter(option => option.value <= currentHours)
    }
    return allOptions
  }

  getEndTimeOptions = () => {
    let allOptions = TO_TIME_OPTIONS
    const isSelectedDateIsToday = moment().isSame(moment(this.props.visitorFilter.dateFilter), 'day')
    if (this.props.visitorFilter.timePeriodType === 'day') {
      const endHours = isSelectedDateIsToday ? moment(this.props.visitorFilter.dateFilter).hours() + 1 : 24
      allOptions = TO_TIME_OPTIONS.filter(option => option.value <= endHours && option.value > this.props.visitorFilter.from)
    }
    return allOptions
  }

  renderTimeFilter() {
    const { visitorFilter } = this.props
    return (
      <div className="time-from-to-wrapper">
        <CheckBox
          className="allday-checkbox"
          checked={visitorFilter.isAllDay}
          label={this.props.t('all_day')}
          value="allday"
          onCheck={this.handleAllDayCheckboxChanged}
          disabled={this.shouldDisabledAllDayCheckbox()}
        />
        <div className="dropdown-from-to-wrapper flex">
          <Dropdown
            className="fromtime-dropdown"
            label={this.props.t('from')}
            dropdownLabel={this.props.t('from')}
            options={this.getStartTimeOptions()}
            value={visitorFilter.from}
            onChange={time => this.onTimeChange('from', time)}
            disabled={visitorFilter.isAllDay}
            placeholder={this.props.t('select_time')}
          />
          <div className="center-line" />
          <Dropdown
            className="totime-dropdown"
            label={this.props.t('to')}
            dropdownLabel={this.props.t('to')}
            options={this.getEndTimeOptions()}
            value={visitorFilter.to}
            onChange={time => this.onTimeChange('to', time)}
            disabled={visitorFilter.isAllDay}
            placeholder={this.props.t('select_time')}
          />
        </div>
        <div className="grey-line" />
      </div>
    )
  }

  renderVehicleTypeFilter() {
    const { listVehicleType } = this.props
    return (
      <div className="vehicle-filter-wrapper">
        <MultiSelectDropdown
          label={this.props.t('vehicle_type')}
          allTagText={this.props.t('all_type')}
          placeholder={this.props.t('select_vehicle_type')}
          disabled={false}
          id="vehicle-filter"
          options={listVehicleType}
          value={this.props.visitorFilter.vehicleType}
          onChange={value => this.props.onMultiDropdownChange('vehicleType', value)}
        />
        <div className="grey-line" />
      </div>
    )
  }

  renderPurposeOfVisitFilter() {
    const { listPurposeVisit } = this.props
    return (
      <div className="purpose-filter-wrapper">
        <MultiSelectDropdown
          label={this.props.t('purpose_of_visit')}
          allTagText={this.props.t('all_purpose')}
          placeholder={this.props.t('select_purpose_of_visit')}
          disabled={false}
          id="purpose-filter"
          options={listPurposeVisit}
          value={this.props.visitorFilter.purpose}
          onChange={value => this.props.onMultiDropdownChange('purpose', value)}
        />
        <div className="grey-line" />
      </div>
    )
  }

  renderCompanyContactFilter() {
    const { listAllDestination } = this.props
    return (
      <div className="company-filter-wrapper">
        <MultiSelectDropdown
          label={this.props.t('company_contact')}
          allTagText={this.props.t('all_company')}
          placeholder={this.props.t('select_company')}
          disabled={false}
          id="company-filter"
          options={listAllDestination}
          value={this.props.visitorFilter.company}
          onChange={value => this.props.onMultiDropdownChange('company', value)}
          searchable
        />
        <div className="grey-line" />
      </div>
    )
  }

  renderSortByFilter() {
    const mockButtons = [
      { text: this.props.t('newest'), value: 'DESC' },
      { text: this.props.t('oldest'), value: 'ASC' },
    ]
    return (
      <div className="sortby-container">
        <Label className="sortby-label" text={this.props.t('sort_by')} />
        <div className="btn-group-wrapper">
          <ButtonGroup
            className="sortby-btn-group"
            buttonList={mockButtons}
            onEachButtonClick={value => this.props.onSortByChange(value)}
            activeSortBy={this.props.visitorFilter.sortBy}
          />
        </div>
      </div>
    )
  }

  onApplyFilter = () => {
    this.props.onFilterChange()
    this.closePopup()
  }

  renderFilterFooter() {
    return (
      <div className="filter-footer-wrapper">
        <div className="grey-line" />
        <Button className="apply-filter-button" disabled={false} text={this.props.t('apply_filter')} onClick={this.onApplyFilter} />
      </div>
    )
  }

  renderFilterPopUp() {
    let className = 'popup-wrapper'
    className += this.state.isPopupOpen ? ' open' : ''
    return (
      <div className={className}>
        {this.renderFilterHeader()}
        <div className="filter-container">
          {this.renderCameraFilter()}
          {this.renderTimePeriod()}
          {this.renderTimeFilter()}
          {this.renderVehicleTypeFilter()}
          {this.renderPurposeOfVisitFilter()}
          {this.renderCompanyContactFilter()}
          {this.renderSortByFilter()}
        </div>
        {this.renderFilterFooter()}
      </div>
    )
  }

  renderOverlay() {
    let classFilter = 'filter-overlay'
    classFilter += this.state.isPopupOpen ? ' open' : ''
    return <div onClick={this.onOverlayClick} className={classFilter}></div>
  }

  onOverlayClick = () => {
    this.closePopup()
  }

  render() {
    return (
      <FilterStyled className={this.props.className}>
        {this.renderIcon()}
        {this.renderFilterPopUp()}
        {this.renderOverlay()}
      </FilterStyled>
    )
  }
}

FilterComponent.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  visitorFilter: PropTypes.shape({
    camera: PropTypes.array,
    timePeriodType: PropTypes.string,
    dateFilter: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    isAllDay: PropTypes.bool,
    from: PropTypes.number,
    to: PropTypes.number,
    vehicleType: PropTypes.array,
    purpose: PropTypes.array,
    company: PropTypes.array,
    sortBy: PropTypes.string,
  }).isRequired,
  listCamera: PropTypes.array.isRequired,
  listAllDestination: PropTypes.array.isRequired,
  listPurposeVisit: PropTypes.array.isRequired,
  listVehicleType: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func,
  onMultiDropdownChange: PropTypes.func,
  onTimeChange: PropTypes.func,
  onAllDayCheckBoxChange: PropTypes.func,
  onSortByChange: PropTypes.func,
  onFilterReset: PropTypes.func,
}

export default withTranslation()(FilterComponent)
